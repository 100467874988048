

const Footer = ({facilityName }) => {

    return (
        <div  style={{backgroundColor:"#343a40", position:"relative",left:0,right:0}}>
            <div style={{ fontSize: 13, color:"rgba(255, 255, 255, 0.5)" }} className='text-center py-3'>
               &copy; {new Date().getFullYear()} {facilityName} | Powered by&nbsp;
                <a href="https://tigercorrectional.com/" target="_blank" style={{color:"rgba(255, 255, 255, 0.5)"}} >
                    Tiger Correctional Services
                </a>
            </div>
            
        </div>
    )
}

export default Footer