import { Link } from "react-router-dom";
import Header from "../components/header";
import { Button, Card } from "react-bootstrap"
import { setRulesAgree } from "../redux/actions";
import { connect } from "react-redux";
import CheckBox from "../components/CheckBox";
import { Markup } from 'interweave';

const Rules = ({ url, rulesAgreeState, setRulesAgree, facilityInformationState }) => {

    return (

        <Header url={url} facility={facilityInformationState} useNavigatePrompt={false} >

            <div className="d-flex flex-column flex-md-row justify-content-start" style={{ paddingLeft: "2rem", paddingRight: "1.5rem" }}>
                <Card style={{ display: "flex", flex: 1, marginBottom: "20px", marginTop: "20px" }}>
                    <Card.Header as="h5">Police Report Filing Rules</Card.Header>
                    <Card.Body style={{ backgroundColor: "#D4EDDA" }}>
                        <Markup content={facilityInformationState.rulesVerbiage} />
                    </Card.Body>
                </Card>

            </div>
            <div style={{ margin: "0px 50px 20px 50px" }}>
                <CheckBox
                    title="By checking this box, you agree to all of the rules listed above."
                    onChange={setRulesAgree}
                    value={rulesAgreeState}
                />
                <div>
                    <Link to={url + "procedures"}>
                        <Button variant="primary" disabled={!rulesAgreeState}>
                            Continue to Filing Procedures
                        </Button>
                    </Link>
                </div>
            </div>
        </Header>
    )
}

const mapStateToProps = state => ({
    rulesAgreeState: state.RulesAgreeState,
    facilityInformationState: state.FacilityInformationState,
})


export default connect(mapStateToProps, { setRulesAgree })(Rules);