import Header from "../components/header";
import { connect } from "react-redux";
import { Button, Table, Card } from "react-bootstrap";
import ReactToPrint from "react-to-print"
import React, { useRef } from 'react';
import Moment from 'moment';
import { useHistory } from "react-router-dom";
import {  useEffect } from 'react'

class ComponentToPrint extends React.Component {

    render() {
        const { incidentInformation, facility, incidentNumber } = this.props
        return (
            <div style={{ paddingTop: 50, margin: "0px 10px 0px 10px" }}>
                <h1>{facility.facilityProperName}</h1>
                <h4>
                    Incident Number: {incidentNumber}
                    <br />
                    Report has been submitted for review.
                    Details have been emailed to {incidentInformation.reporter.emailAddress}.
                </h4>
                <Table borderless>
                    <tbody>
                        <tr>
                            <td>Name: {incidentInformation.reporter.firstName + ' ' + incidentInformation.reporter.lastName}</td>
                        </tr>
                        <tr>
                            <td>Phone: {incidentInformation.reporter.phoneNumber}</td>
                        </tr>
                        <tr>
                            <td>Email: {incidentInformation.reporter.emailAddress}</td>
                        </tr>
                        <tr>
                            <td>Date of Birth: {Moment(incidentInformation.reporter.DOB).format('MM/DD/yyyy')}</td>
                        </tr>
                        <tr>
                            <td>Driver's License: {incidentInformation.reporter.driverLicense}</td>
                        </tr>
                        <tr>
                            <td>Driver's License State: {incidentInformation.reporter.driverLicenseState}</td>
                        </tr>
                        <tr><td>Address:</td></tr>
                        <tr>
                            <td>{incidentInformation.reporter.address.addressLine1}</td>
                        </tr>
                        <tr>
                            <td>
                                {
                                    !incidentInformation.reporter.address.addressLine2 ? <>&nbsp;</> : incidentInformation.reporter.address.addressLine2
                                }
                            </td>
                        </tr>
                        <tr>
                            <td>{incidentInformation.reporter.address.city + ', ' + incidentInformation.reporter.address.state + ' ' + incidentInformation.reporter.address.postalCode}</td>
                        </tr>
                        <tr>
                            <td>Type: {incidentInformation.problemNature}</td>
                        </tr>
                        <tr>
                            <td>Location: {incidentInformation.location}</td>
                        </tr>
                        <tr>
                            <td>Description: {incidentInformation.description}</td>
                        </tr>
                        <tr>
                            <td>Property: {incidentInformation.propertyDescription}</td>
                        </tr>
                        <tr>
                            <td>Begin Time: {Moment(incidentInformation.beginTime).format("MM/DD/yyyy h:mm:ss a")}</td>
                        </tr>
                        <tr>
                            <td>End Time: {Moment(incidentInformation.endTime).format("MM/DD/yyyy h:mm:ss a")}</td>
                        </tr>
                        <tr>
                            <td>Signature: {incidentInformation.signature}</td>
                        </tr>
                        <tr>
                            <td>Signature Date: {incidentInformation.signatureDate}</td>
                        </tr>
                    </tbody>
                </Table>
            </div>
        );
    }
}

const Success = ({ url, rulesAgreeState, incidentState, facilityInformationState, incidentNumber }) => {

    let history = useHistory();

    //route to home if the user types in the direct url
    if (!rulesAgreeState) {
        history.push(url);
    }
    let componentRef = useRef();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])

    return (

        <Header url={url} facility={facilityInformationState} useNavigatePrompt={false} >
            <Card style={{ marginBottom: "20px" }}>
                <Card.Body as="h5" >
                    Incident Number: {incidentNumber}
                    <br />
                    Report has been submitted for review.
                    Details have been emailed to {incidentState.reporter.emailAddress}.
                </Card.Body>
            </Card>
            <div className="d-flex flex-column flex-md-row justify-content-start" style={{ paddingLeft: "2rem", paddingRight: "1.5rem", width: "100%" }}>

                <Card style={{ display: "flex", flex: 1, marginBottom: "20px" }} >
                    <Card.Header as="h5">Contact Information</Card.Header>
                    <Card.Body>
                        <Table bordered style={{tableLayout:"fixed"}} size="sm">
                            <tbody>
                                <tr>
                                    <td><span className='font-weight-bold'>Name:</span> {incidentState.reporter.firstName + ' ' + incidentState.reporter.lastName}</td>
                                </tr>
                                <tr>
                                    <td><span className='font-weight-bold'>Phone:</span> {incidentState.reporter.phoneNumber}</td>
                                </tr>
                                <tr>
                                    <td><span className='font-weight-bold'>Email:</span> {incidentState.reporter.emailAddress}</td>
                                </tr>
                                <tr>
                                    <td><span className='font-weight-bold'>Date of Birth:</span> {Moment(incidentState.reporter.DOB).format('MM/DD/yyyy')}</td>
                                </tr>
                                <tr>
                                    <td><span className='font-weight-bold'>Driver's License:</span> {incidentState.reporter.driverLicense}</td>
                                </tr>
                                <tr>
                                    <td><span className='font-weight-bold'>Driver's License State:</span> {incidentState.reporter.driverLicenseState}</td>
                                </tr>
                            </tbody>
                        </Table>
                    </Card.Body>
                </Card>
                <Card className="ml-md-4" style={{ display: "flex", flex: 1, marginBottom: "20px" }}>
                    <Card.Header as="h5">
                        Address
                    </Card.Header>
                    <Card.Body>
                        <Table bordered style={{tableLayout:"fixed"}} size="sm">
                            <tbody>
                                <tr>
                                    <td>{incidentState.reporter.address.addressLine1}</td>
                                </tr>
                                <tr>
                                    <td>
                                        {
                                            !incidentState.reporter.address.addressLine2 ? <>&nbsp;</> : incidentState.reporter.address.addressLine2
                                        }
                                    </td>
                                </tr>
                                <tr>
                                    <td>{incidentState.reporter.address.city + ', ' + incidentState.reporter.address.state + ' ' + incidentState.reporter.address.postalCode}</td>
                                </tr>
                            </tbody>
                        </Table>
                    </Card.Body>
                </Card>
                <Card className="ml-md-4" style={{ display: "flex", flex: 1, marginBottom: "20px" }}>
                    <Card.Header as="h5">
                        Incident Information
                    </Card.Header>
                    <Card.Body>
                        <Table bordered style={{tableLayout:"fixed"}} size="sm">
                            <tbody>
                                <tr>
                                    <td><span className='font-weight-bold'>Type:</span> {incidentState.problemNature}</td>
                                </tr>
                                <tr>
                                    <td><span className='font-weight-bold'>Location:</span> {incidentState.location}</td>
                                </tr>
                                <tr>
                                    <td><span className='font-weight-bold'>Description:</span> {incidentState.description}</td>
                                </tr>
                                <tr>
                                    <td><span className='font-weight-bold'>Property:</span> {incidentState.propertyDescription}</td>
                                </tr>
                                <tr>
                                    <td><span className='font-weight-bold'>Begin Time:</span> {Moment(incidentState.beginTime).format("MM/DD/yyyy h:mm:ss a")}</td>
                                </tr>
                                <tr>
                                    <td><span className='font-weight-bold'>End Time:</span> {Moment(incidentState.endTime).format("MM/DD/yyyy h:mm:ss a")}</td>
                                </tr>
                            </tbody>
                        </Table>
                    </Card.Body>
                </Card>
                <Card className="ml-md-4" style={{ display: "flex", flex: 1, marginBottom: "20px" }}>
                    <Card.Header as="h5">
                        Confirmation
                    </Card.Header>
                    <Card.Body >
                        <Table bordered style={{}} size="sm">
                            <tbody>
                                <tr>
                                    <td><span className='font-weight-bold'>Signature:</span> {incidentState.signature}</td>
                                </tr>
                                <tr>
                                    <td><span className='font-weight-bold'>Signature Date:</span> {incidentState.signatureDate}</td>
                                </tr>
                            </tbody>
                        </Table>
                        <div style={{ float: 'right' }}>
                            <div style={{}}>
                                {/* Not using a Link because I all redux values to reset.  */}
                                <a href={url}>
                                    <Button variant="primary">
                                        Report Another Incident
                                    </Button>
                                </a>
                            </div>
                            <div style={{ float: 'right', paddingTop: 25 }}>
                                <ReactToPrint
                                    trigger={() => <Button>Print Incident Details</Button>}
                                    content={() => componentRef}
                                />
                                <div style={{ display: "none" }}>
                                    <ComponentToPrint
                                        incidentInformation={incidentState}
                                        facility={facilityInformationState}
                                        incidentNumber={incidentNumber}
                                        ref={el => (componentRef = el)} />
                                </div>
                            </div>
                        </div>
                    </Card.Body>
                </Card>
            </div>
        </Header >
    )
}

const mapStateToProps = state => ({
    incidentState: state.IncidentState,
    facilityInformationState: state.FacilityInformationState,
    rulesAgreeState: state.RulesAgreeState,
    incidentNumber: state.IncidentNumberState
})

export default connect(mapStateToProps, {})(Success);