import StyledInput from '../components/StyledInput'
import StyledTextarea from '../components/StyledTextarea'
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { setIncident, setIncidentInitialLoad } from "../redux/actions";
import { useState, useEffect } from 'react'
import { DefaultIncidentInfoErrorState } from "../helpers/DefaultContactInfoErrorState";
import { Breadcrumb, Button, Card } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";
import Header from "../components/header";
import IncidentDropdown from "../components/IncidentDropdown";
import { useHistory } from "react-router-dom";

const Incident = ({ url, rulesAgreeState, incidentState, setIncident, incidentInitialLoad, setIncidentInitialLoad, facilityInformationState }) => {

    let history = useHistory();

    const [errors, setErrors] = useState(DefaultIncidentInfoErrorState);

    const requiredText = '*Required'

    useEffect(() => {
        window.scrollTo(0, 0);
        //route to home if the user types in the direct url
        if (!rulesAgreeState) {
            history.push(url);
        }
        
        //when we mount, run this function
        // do not validate data on the inital mount
        if (incidentInitialLoad) {
            setIncidentInitialLoad(false);

        } else {
            validateAllInput();
        }
    }, [])

    const Proceed = (e) => {
        let hasErrored = false;

        Object.keys(errors).map((error, index) => {
            if (!errors[error]) {
                hasErrored = true;
            }
        })

        if (hasErrored) {
            //this should show the errors on the controls that need input
            validateAllInput();
            e.preventDefault();
        }
    }

    const validateAllInput = () => {
        let allErrors = { ...errors };
        allErrors = validateInputOnChange(incidentState.problemNature, "problemNature", allErrors);
        allErrors = validateInputOnChange(incidentState.location, "location", allErrors);
        allErrors = validateInputOnChange(incidentState.beginTime, "beginTime", allErrors);
        allErrors = validateInputOnChange(incidentState.endTime, "endTime", allErrors);
        allErrors = validateInputOnChange(incidentState.description, "description", allErrors);
        setErrors(allErrors);
    }

    const validateInputOnChange = (text, field, allErrors) => {
        let newErrors = null;

        if (!allErrors) {
            newErrors = { ...errors };
        }
        else {
            newErrors = { ...allErrors };
        }

        let newIncidentInformation = { ...incidentState };

        switch (field) {
            case "problemNature":
                if (text === "0" || !text) {
                    //string is emtpy null
                    //set error state to true because we have an error
                    newErrors.problemNature = false;
                }
                else {
                    newErrors.problemNature = true;
                }
                newIncidentInformation.problemNature = text;
                break;
            case "location":
                if (!text) {
                    newErrors.location = false;
                }
                else {
                    newErrors.location = true;
                }
                newIncidentInformation.location = text;
                break;
            case "beginTime":
                if (!text) {
                    newErrors.beginTime = false;
                }
                else {
                    newErrors.beginTime = true;
                }
                newIncidentInformation.beginTime = text;
                break;
            case "endTime":
                if (!text) {
                    newErrors.endTime = false;
                }
                else {
                    newErrors.endTime = true;
                }
                newIncidentInformation.endTime = text;
                break;
            case "description":
                if (!text) {
                    newErrors.description = false;
                }
                else {
                    newErrors.description = true;
                }
                newIncidentInformation.description = text;
                break;
            case "propertyDescription":
                newIncidentInformation.propertyDescription = text;
                break;
        }

        if (allErrors == null) {
            setErrors(newErrors);
        }
        else {
            return newErrors;
        }

        setIncident(newIncidentInformation);
    }

    return (
        <div>
            <Header url={url} facility={facilityInformationState} useNavigatePrompt={true} >
                <Breadcrumb style={{}}>
                    <LinkContainer to={url + "contact"} exact>
                        <Breadcrumb.Item >Contact Information</Breadcrumb.Item>
                    </LinkContainer>
                    <Breadcrumb.Item active>Incident Information</Breadcrumb.Item>
                </Breadcrumb>

                <div className="d-flex flex-column flex-md-row justify-content-start" style={{ paddingLeft: "2rem", paddingRight: "1.5rem", width: "100%" }}>
                    <Card style={{ display: "flex", flex: 1, marginBottom: "20px" }} >
                        <Card.Header as="h5">Incident Information</Card.Header>
                        <Card.Body>
                            <IncidentDropdown
                                style={{ width: 195 }}
                                title={"What type of incident occured?"}
                                isValid={errors.problemNature}
                                validationMessage={requiredText}
                                onChangeText={(text) => validateInputOnChange(text, 'problemNature')}
                                value={incidentState.problemNature}
                            >
                                <option value="0">-Select One-</option>
                                {
                                    facilityInformationState.incidentTypes.map((problemNature) =>
                                        <option key={problemNature} value={problemNature}>{problemNature}</option>
                                    )
                                }
                            </IncidentDropdown>

                            <StyledTextarea
                                style={{ width: "100%" }}
                                rows={3}
                                title={"Where did the incident occur?"}
                                isValid={errors.location}
                                validationMessage={requiredText}
                                onChangeText={(text) => validateInputOnChange(text, 'location')}
                                value={incidentState.location}
                            >
                                <p style={{ fontSize: 12 }}>
                                    {facilityInformationState.incidentWhereNote}
                                </p>
                            </StyledTextarea>
                            <StyledInput
                                title={"Begin Time"}
                                type={"datetime-local"}
                                isValid={errors.beginTime}
                                validationMessage={requiredText}
                                onChangeText={(text) => validateInputOnChange(text, 'beginTime')}
                                value={incidentState.beginTime}
                            />
                            <StyledInput
                                title={"End Time"}
                                type={"datetime-local"}
                                isValid={errors.endTime}
                                validationMessage={requiredText}
                                onChangeText={(text) => validateInputOnChange(text, 'endTime')}
                                value={incidentState.endTime}
                            />
                        </Card.Body>
                    </Card>
                    <Card className="ml-md-4" style={{ display: "flex", flex: 1, marginBottom: "20px" }}>
                        <Card.Header as="h5">
                            Incident Description
                        </Card.Header>
                        <Card.Body>
                            <StyledTextarea
                                style={{ width: "100%" }}
                                rows={4}
                                title={"Describe the incident in detail."}
                                isValid={errors.description}
                                validationMessage={requiredText}
                                onChangeText={(text) => validateInputOnChange(text, 'description')}
                                value={incidentState.description}
                            />
                            <StyledTextarea
                                style={{ width: "100%" }}
                                rows={4}
                                title={"If there is any property mentioned in the incident, please describe it in detail."}
                                isValid={errors.propertyDescription}
                                validationMessage={requiredText}
                                onChangeText={(text) => validateInputOnChange(text, 'propertyDescription')}
                                value={incidentState.propertyDescription}
                            >
                                <div style={{}}>
                                    <p style={{ fontSize: 12 }}>
                                        {facilityInformationState.incidentPropertyNote}
                                    </p>
                                </div>

                            </StyledTextarea>
                            <div style={{ float: "right" }}>
                                <Link to={url + "summary"}>
                                    <Button variant="primary" onClick={e => Proceed(e)}>
                                        Continue to Summary and Electronic Signature
                                    </Button>
                                </Link>
                            </div>
                        </Card.Body>
                    </Card>
                </div>
            </Header>

        </div>
    )
}

const mapStateToProps = state => ({
    incidentState: state.IncidentState,
    incidentInitialLoad: state.IncidentInitialLoad,
    facilityInformationState: state.FacilityInformationState,
    rulesAgreeState: state.RulesAgreeState
})

export default connect(mapStateToProps, { setIncident, setIncidentInitialLoad })(Incident);

