import React from "react";
import { Nav, Navbar, NavDropdown } from "react-bootstrap";
import { NAVIGATE_AWAY_ALERT } from "../constants";
import Footer from "./footer";

const Header = ({ url, facility, children, useNavigatePrompt }) => {
    return (
        <div>
            {/* the style below forces that div to take the entire page minus the footer height */}
            <div style={{minHeight:"calc(100vh - 52px)"}}> 
                <Navbar bg="dark" variant="dark" expand="lg">
                    <Navbar.Brand
                        onClick={(e) => { if (useNavigatePrompt) if (!window.confirm(NAVIGATE_AWAY_ALERT)) e.preventDefault() }}
                        href={url}
                    >
                        {facility?.headerVerbiage}
                    </Navbar.Brand>
                    <Navbar.Toggle aria-controls="basic-navbar-nav" />
                    <Navbar.Collapse id="basic-navbar-nav">
                        <Nav className="me-auto">
                            <Nav.Link href={facility.agencyURL} target="_blank">Sheriff's Office</Nav.Link>
                            <Nav.Link onClick={(e) => { if (useNavigatePrompt) if (!window.confirm(NAVIGATE_AWAY_ALERT)) e.preventDefault() }} href={url + 'rules'}>File Incident Report</Nav.Link>
                            {
                                !facility.jailRosterURL ?
                                    ''
                                    :
                                    <Nav.Link href={facility.jailRosterURL} target="_blank">Jail Roster</Nav.Link>
                            }
                            {
                                !facility.institutionURL ?
                                    ''
                                    :
                                    <Nav.Link href={facility.institutionURL} target="_blank">Jail Information</Nav.Link>
                            }
                            <Nav.Link href={url+'privacy'} target="_blank">Privacy Policy</Nav.Link>
                        </Nav>
                    </Navbar.Collapse>
                </Navbar>
                {children}
            </div>
            <Footer facilityName={facility.facilityProperName} />
        </div>
    )
}

export default Header

