

const Denied = () => {
    return (
        <div style={{
            textAlign: "center",
            paddingTop: 150
        }}>
            <h1>
                404
            </h1>
            <h3>Page Not Found</h3>
            <div>
                If you are trying to file a police report, contact your local law enforcement agency.
            </div>
        </div>
    )
}

export default Denied