import React from 'react';
import styled from "styled-components";

const Select = styled.select`
  outline: none !important;
  border-style:solid;
  border-width:1px;
  border-color: ${({ borderColor }) => borderColor};
  margin: 5px 0 5px 0; 
  padding:5px;
  border-radius:3px;
  background-color: ${({ disabled }) => disabled === true ? "gray" : "inherit"};
`
const TigerStateDropdown = ({ title, titleStyles, type, disabled, isValid,
    validationMessage, validationStyles, onChangeText, onChange,
    value, containerStyle, style, children }) => {

    const getBorderColor = () => {
        if (isValid === null || isValid === undefined)
            return "black";
        if (isValid)
            return "green"
        return "red";
    }

    return (
        <div style={{ margin: 10, ...containerStyle }}>
            {
                title &&
                <p style={{ margin: 0, ...titleStyles }}>{title}</p>
            }
            <Select
                borderColor={getBorderColor()}
                style={{ ...style }}
                value={value}
                type={type}
                disabled={disabled}
                onChange={(e) => {
                    if (disabled)
                        return;
                    if (onChange)
                        onChange(e)
                    if (onChangeText)
                        onChangeText(e.target.value)

                }}
            >
                <option value='0'>--</option>
                <option value='AL'>AL</option>
                <option value='AK'>AK</option>
                <option value='AZ'>AZ</option>
                <option value='AR'>AR</option>
                <option value='CA'>CA</option>
                <option value='CO'>CO</option>
                <option value='CT'>CT</option>
                <option value='DE'>DE</option>
                <option value='DC'>DC</option>
                <option value='FL'>FL</option>
                <option value='GA'>GA</option>
                <option value='HI'>HI</option>
                <option value='ID'>ID</option>
                <option value='IL'>IL</option>
                <option value='IN'>IN</option>
                <option value='IA'>IA</option>
                <option value='KS'>KS</option>
                <option value='KY'>KY</option>
                <option value='LA'>LA</option>
                <option value='ME'>ME</option>
                <option value='MD'>MD</option>
                <option value='MA'>MA</option>
                <option value='MI'>MI</option>
                <option value='MN'>MN</option>
                <option value='MS'>MS</option>
                <option value='MO'>MO</option>
                <option value='MT'>MT</option>
                <option value='NE'>NE</option>
                <option value='NV'>NV</option>
                <option value='NH'>NH</option>
                <option value='NJ'>NJ</option>
                <option value='NM'>NM</option>
                <option value='NY'>NY</option>
                <option value='NC'>NC</option>
                <option value='ND'>ND</option>
                <option value='OH'>OH</option>
                <option value='OK'>OK</option>
                <option value='OR'>OR</option>
                <option value='PA'>PA</option>
                <option value='PR'>PR</option>
                <option value='RI'>RI</option>
                <option value='SC'>SC</option>
                <option value='SD'>SD</option>
                <option value='TN'>TN</option>
                <option value='TX'>TX</option>
                <option value='UT'>UT</option>
                <option value='VT'>VT</option>
                <option value='VA'>VA</option>
                <option value='WA'>WA</option>
                <option value='WV'>WV</option>
                <option value='WI'>WI</option>
                <option value='WY'>WY</option>

            </Select>
            {
                isValid === false &&
                <p style={{ margin: 5, fontSize: 12, color: "red", ...validationStyles }}>{validationMessage}</p>
            }
        </div>
    );
};
export default TigerStateDropdown;