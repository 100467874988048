export const SET_INCIDENT = "SET_INCIDENT"

export const CONTACT_INFO_INIT_LOAD = "CONTACT_INFO_INIT_LOAD"
export const INCIDENT_INFO_INIT_LOAD = "INCIDENT_INFO_INIT_LOAD"
export const SUMMARY_INIT_LOAD = "SUMMARY_INIT_LOAD"

export const SET_WINDOW = "SET_WINDOW"

export const RULES_AGREE = "RULES_AGREE"
export const PROCEDURES_AGREE = "PROCEDURES_AGREE"

export const FACILITY_INFORMATION = "FACILITY_INFORMATION"

export const INCIDENT_NUMBER = "INCIDENT_NUMBER"

