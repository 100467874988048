import React from 'react';
import styled from "styled-components";

const Textarea = styled.textarea`
  outline: none !important;
  border-style:solid;
  border-width:1px;
  border-color: ${({borderColor}) => borderColor};
  margin: 5px 0 5px 0; 
  padding:5px;
  border-radius:3px;
  background-color: ${({disabled}) => disabled === true ? "gray":"inherit"};
`

const StyledTextarea = ({rows,cols,title, titleStyles, disabled, isValid, placeholder,
                         validationMessage, validationStyles, onChangeText, onChange,
                         value, containerStyle, style, children}) => {
    const getBorderColor = () => {
        if (isValid === null || isValid === undefined)
            return "black";
        if (isValid)
            return "green";
        return "red";
    }
    return (
        <div style={{margin:10, ...containerStyle}}>
            {
                title &&
                    <p style={{margin:0, ...titleStyles}}>{title}</p>
            }
            <Textarea
                rows={rows}
                cols={cols}
                borderColor={getBorderColor()}
                style={{...style}}
                value={value}
                disabled={disabled}
                placeholder={placeholder}
                onChange={(e) => {
                    if (disabled)
                        return;
                    if (onChange)
                        onChange(e)
                    if (onChangeText)
                        onChangeText(e.target.value)
                }}
            />
                {
                    isValid === false &&
                        <p style={{margin:5, fontSize:12, color:"red", ...validationStyles}}>{validationMessage}</p>
                }
                {children}
        </div>
    );
};
export default StyledTextarea;