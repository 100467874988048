export const FacilityInformation = {
    id:"",
    isWebActive: false,  
    facilityProperName: "",  
    mailingAddress: "",  
    shippingAddress: "",  
    jailRosterURL: "",  
    agencyURL: "",  
    institutionURL: "",  
    institutionProperName: "",  
    rulesVerbiage: "",
    procedureVerbiage: "",
    incidentWhereNote: "",
    incidentPropertyNote: "",
    physicalAddressNote: "",
    summaryVerbiage: "",
    agencyVerbiage:"",
    headerVerbiage:"",
    incidentTypes:[]

}