import { Link } from "react-router-dom";
import { Breadcrumb, Button, Card } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";
import CheckBox from "../components/CheckBox";
import Header from "../components/header";
import { connect } from "react-redux";
import { setProceduresAgree } from "../redux/actions";
import { Markup } from 'interweave';
import { useHistory } from "react-router-dom";
import {  useEffect } from 'react'

const Procedures = ({ url, proceduresAgreeState,rulesAgreeState, setProceduresAgree, facilityInformationState }) => {

    let history = useHistory();

    //route to home if the user types in the direct url
    if(!rulesAgreeState){
        history.push(url);
    }

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])

    return (
        <Header url={url} facility={facilityInformationState} useNavigatePrompt={true} >
            <Breadcrumb style={{}}>
                <LinkContainer to={url + "rules"} exact>
                    <Breadcrumb.Item href={url} >Rules</Breadcrumb.Item>
                </LinkContainer>
                <Breadcrumb.Item active>Procedures</Breadcrumb.Item>
            </Breadcrumb>

            <div className="d-flex flex-column flex-md-row justify-content-start" style={{ paddingLeft: "2rem", paddingRight: "1.5rem" }}>
                <Card style={{ display: "flex", flex: 1, marginBottom: "20px" }}>
                    <Card.Header as="h5">Police Report Procedures</Card.Header>
                    <Card.Body style={{ backgroundColor: "#D4EDDA" }}>
                        <Markup content={facilityInformationState.procedureVerbiage} />
                    </Card.Body>
                </Card>
            </div>
            <div style={{ margin: "0px 50px 20px 50px" }}>
                <CheckBox
                    title="By checking this box, you acknowledge that you have read and understand the procedures detailed above."
                    onChange={setProceduresAgree}
                    value={proceduresAgreeState}
                />
                <div>
                    <Link to={url + "contact"}>
                        <Button variant="primary" disabled={!proceduresAgreeState}>
                            Continue to Contact Information
                        </Button>
                    </Link>
                </div>
            </div>
        </Header >
    )
}

const mapStateToProps = state => ({
    proceduresAgreeState: state.ProceduresAgreeState,
    facilityInformationState: state.FacilityInformationState,
    rulesAgreeState: state.RulesAgreeState
})


export default connect(mapStateToProps, { setProceduresAgree })(Procedures);