
//used for server communication errors.
const Error = () => {
    return (
        <div style={{
            textAlign: "center",
            paddingTop: 150
        }}>
            <h1>
                An Error has occurred.
            </h1>
            
            <div>
                If you are trying to file a police report, contact your local law enforcement agency.
            </div>
        </div>
    )
}

export default Error