
export const DefaultIncidentState = {
    facilityId: '',
    source: "PUBLIC",    
    problemNature: '',
    location: '',
    beginTime: '' ,
    endTime: '',
    description: '',
    propertyDescription: '',
    signature: '',
    signatureDate:'',
    reporter: {
        firstName: '',
        lastName: '',
        DOB: '',
        emailAddress: '',
        phoneNumber: '',
        address: {
            addressLine1: '',
            addressLine2: '',
            city: '',
            state: '',
            postalCode: ''
        },
        driverLicense: '',
        driverLicenseState: ''
    },
    reCaptchaToken:''
};