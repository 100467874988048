import StyledInput from '../components/StyledInput';
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { useState, useEffect } from 'react'
import { setIncident, setContactInitialLoad } from "../redux/actions";
import { DefaultContactInfoErrorState } from "../helpers/DefaultContactInfoErrorState";
import { Breadcrumb, Button, Card } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";
import Header from "../components/header";
import TigerStateDropdown from '../components/TigerStateDropdown';
import { EMAIL_REGEX, PHONE_REGEX } from '../constants';
import { useHistory } from "react-router-dom";
import moment from 'moment';

const Contact = ({ url, incidentState, setIncident, rulesAgreeState, contactInfoInitialLoad, setContactInitialLoad, facilityInformationState }) => {

    let history = useHistory();

    //route to home if the user types in the direct url
    if (!rulesAgreeState) {
        history.push(url);
    }

    const [errors, setErrors] = useState(DefaultContactInfoErrorState);
    const validEmail = new RegExp(EMAIL_REGEX);
    const validPhone = new RegExp(PHONE_REGEX);

    const requiredText = '*Required';

    useEffect(() => {

        window.scrollTo(0, 0);
        //when we mount, run this function
        // do not validate data on the inital mount
        if (contactInfoInitialLoad) {
            setContactInitialLoad(false);

        } else {
            validateAllInput();
        }
    }, [])

    const Proceed = (e) => {

        let hasErrored = false;

        Object.keys(errors).map((error, index) => {
            if (!errors[error]) {
                hasErrored = true;
            }
        })

        if (hasErrored) {
            //this should show the errors on the controls that need input
            validateAllInput();
            e.preventDefault();
        }

        let newIncidentInformation = { ...incidentState };
        newIncidentInformation.facilityId = facilityInformationState.id;
        setIncident(newIncidentInformation);
    }

    const validateAllInput = () => {
        let allErrors = { ...errors };

        allErrors = validateInputOnChange(incidentState.reporter.firstName, "reporterFirstName", allErrors);
        allErrors = validateInputOnChange(incidentState.reporter.lastName, "reporterLastName", allErrors);
        allErrors = validateInputOnChange(incidentState.reporter.emailAddress, "reporterEmail", allErrors);
        allErrors = validateInputOnChange(incidentState.reporter.DOB, "reporterDOB", allErrors);
        allErrors = validateInputOnChange(incidentState.reporter.phoneNumber, "reporterPhone", allErrors);
        allErrors = validateInputOnChange(incidentState.reporter.address.addressLine1, "reporterAddressStreet1", allErrors);
        allErrors = validateInputOnChange(incidentState.reporter.address.city, "reporterCity", allErrors);
        allErrors = validateInputOnChange(incidentState.reporter.address.state, "reporterState", allErrors);
        allErrors = validateInputOnChange(incidentState.reporter.address.postalCode, "reporterZip", allErrors);

        setErrors(allErrors);
    }

    const validateDOB = (dob) => 
    {
        //make sure the date of birth is within reason.
        var dobMoment = moment(dob);
        var now = moment();
        var difference = now.diff(dobMoment,'years',true);

        if(difference > 5 && difference < 120){
            return true;
        }
       
        return false;
    }

    const validateInputOnChange = (text, field, allErrors) => {
        let newErrors = null;

        if (!allErrors) {
            newErrors = { ...errors };
        }
        else {
            newErrors = { ...allErrors };
        }

        let newIncidentInformation = { ...incidentState };

        switch (field) {
            case "reporterFirstName":
                if (!text) {
                    //string is emtpy null
                    //set error state to true because we have an error
                    newErrors.reporterFirstName = false;
                }
                else {
                    newErrors.reporterFirstName = true;
                }
                newIncidentInformation.reporter.firstName = text;
                break;
            case "reporterLastName":
                if (!text) {
                    newErrors.reporterLastName = false;
                }
                else {
                    newErrors.reporterLastName = true;
                }
                newIncidentInformation.reporter.lastName = text;
                break;

            case "reporterDOB":
                if (!text) {
                    newErrors.reporterDOB = false;
                }
                else {
                    newErrors.reporterDOB = validateDOB(text);
                }
                newIncidentInformation.reporter.DOB = text;
                break;
            case "reporterEmail":

                if (!text || !validEmail.test(text)) {
                    newErrors.reporterEmail = false;
                }
                else {
                    newErrors.reporterEmail = true;
                }
                newIncidentInformation.reporter.emailAddress = text;
                break;
            case "reporterPhone":
                if (!text || !validPhone.test(text)) {
                    newErrors.reporterPhone = false;
                }
                else {
                    newErrors.reporterPhone = true;
                }
                newIncidentInformation.reporter.phoneNumber = text;
                break;
            case "reporterAddressStreet1":
                if (!text) {
                    newErrors.reporterAddressStreet1 = false;
                }
                else {
                    newErrors.reporterAddressStreet1 = true;
                }
                newIncidentInformation.reporter.address.addressLine1 = text;
                break;
            case "reporterAddressStreet2":
                //no validation needed at this time. Not required
                newIncidentInformation.reporter.address.addressLine2 = text;
                break;
            case "reporterCity":
                if (!text) {
                    newErrors.reporterCity = false;
                }
                else {
                    newErrors.reporterCity = true;
                }
                newIncidentInformation.reporter.address.city = text;
                break;
            case "reporterState":
                if (text === '0' || !text) {
                    newErrors.reporterState = false;
                }
                else {
                    newErrors.reporterState = true;
                }
                newIncidentInformation.reporter.address.state = text;
                break;
            case "reporterZip":
                if (!text) {
                    newErrors.reporterZip = false;
                }
                else {
                    newErrors.reporterZip = true;
                }
                newIncidentInformation.reporter.address.postalCode = text;
                break;
            case "driverLicense":
                //no validation needed at this time. Not required
                newIncidentInformation.reporter.driverLicense = text;
                break;
            case "driverLicenseState":
                //no validation needed at this time. Not required
                if (text === '0') {
                    newIncidentInformation.reporter.driverLicenseState = '';
                }
                else {
                    newIncidentInformation.reporter.driverLicenseState = text;
                }

                break;
        }

        if (allErrors == null) {
            setErrors(newErrors);
        }
        else {
            return newErrors;
        }

        setIncident(newIncidentInformation);
    }

    return (
        <Header url={url} facility={facilityInformationState} useNavigatePrompt={true}>
            <Breadcrumb style={{}}>
                <LinkContainer to={url + "procedures"} exact>
                    <Breadcrumb.Item>Procedures</Breadcrumb.Item>
                </LinkContainer>
                <Breadcrumb.Item active>Contact Information</Breadcrumb.Item>
            </Breadcrumb>

            <div className="d-flex flex-column flex-md-row justify-content-start" style={{ paddingLeft: "2rem", paddingRight: "1.5rem" }}>
                <Card style={{ display: "flex", flex: 1, marginBottom: "20px" }}>
                    <Card.Header as="h5">Contact Information</Card.Header>
                    <Card.Body>
                        <div style={{ display: "flex", flexWrap: "wrap" }}>
                            <StyledInput
                                title={"First Name"}
                                type={"text"}
                                isValid={errors.reporterFirstName}
                                validationMessage={requiredText}
                                onChangeText={(text) => validateInputOnChange(text, 'reporterFirstName')}
                                value={incidentState.reporter.firstName}
                            />
                            <StyledInput
                                title={"Last Name"}
                                type={"text"}
                                isValid={errors.reporterLastName}
                                validationMessage={requiredText}
                                onChangeText={(text) => validateInputOnChange(text, 'reporterLastName')}
                                value={incidentState.reporter.lastName}
                            />
                        </div>
                        <div style={{ display: "flex", flexWrap: "wrap" }}>
                            <StyledInput
                                title={"Date of Birth"}
                                type={"date"}
                                isValid={errors.reporterDOB}
                                validationMessage={requiredText}
                                onChangeText={(text) => validateInputOnChange(text, 'reporterDOB')}
                                value={incidentState.reporter.DOB}
                            />
                            <StyledInput
                                title={"Phone Number"}
                                type={"text"}
                                placeholder={"ex: 555-555-5555"}
                                isValid={errors.reporterPhone}
                                validationMessage={requiredText}
                                onChangeText={(text) => validateInputOnChange(text, 'reporterPhone')}
                                value={incidentState.reporter.phoneNumber}
                            />
                        </div>
                        <StyledInput
                            title={"Email Address"}
                            type={"text"}
                            isValid={errors.reporterEmail}
                            validationMessage={requiredText}
                            onChangeText={(text) => validateInputOnChange(text, 'reporterEmail')}
                            value={incidentState.reporter.emailAddress}
                            style={{ width: "100%" }}
                        />

                    </Card.Body>
                </Card>
                <Card className="ml-md-4" style={{ display: "flex", flex: 1, marginBottom: "20px" }}>
                    <Card.Header as="h5">
                        Personal Information
                    </Card.Header>
                    <Card.Body>
                        <StyledInput
                            title={"Physical Address"}
                            type={"text"}
                            placeholder={"ex: 123 Main St"}
                            isValid={errors.reporterAddressStreet1}
                            validationMessage={requiredText}
                            onChangeText={(text) => validateInputOnChange(text, 'reporterAddressStreet1')}
                            value={incidentState.reporter.address.addressLine1}
                        />

                        <StyledInput
                            type={"text"}
                            placeholder={"ex: Suite 101"}
                            onChangeText={(text) => validateInputOnChange(text, 'reporterAddressStreet2')}
                            value={incidentState.reporter.address.addressLine2}
                        >
                            <p style={{ fontSize: 12 }}>
                                {facilityInformationState.physicalAddressNote}
                            </p>
                        </StyledInput>
                        <div style={{ display: "flex", flexWrap: "wrap" }}>
                            <StyledInput
                                title={"City"}
                                type={"text"}
                                isValid={errors.reporterCity}
                                validationMessage={requiredText}
                                onChangeText={(text) => validateInputOnChange(text, 'reporterCity')}
                                value={incidentState.reporter.address.city}
                            />

                            <TigerStateDropdown
                                title={'State'}
                                isValid={errors.reporterState}
                                validationMessage={requiredText}
                                onChangeText={(text) => validateInputOnChange(text, 'reporterState')}
                                value={incidentState.reporter.address.state}
                            />

                            <StyledInput
                                title={"Zip"}
                                type={"text"}
                                maxLength={5}
                                isValid={errors.reporterZip}
                                validationMessage={requiredText}
                                onChangeText={(text) => validateInputOnChange(text, 'reporterZip')}
                                value={incidentState.reporter.address.postalCode}
                                style={{ width: 60 }}
                            />
                        </div>
                        <div style={{ display: "flex", flexWrap: "wrap" }}>
                            <StyledInput
                                title={"Driver's License Number"}
                                type={"text"}
                                onChangeText={(text) => validateInputOnChange(text, 'driverLicense')}
                                value={incidentState.reporter.driverLicense}
                            />

                            <TigerStateDropdown
                                title={"Driver's License State"}
                                onChangeText={(text) => validateInputOnChange(text, 'driverLicenseState')}
                                value={incidentState.reporter.driverLicenseState}
                            />

                        </div>
                        <div style={{ float: "right" }}>
                            <Link to={url + "incident"}>
                                <Button variant="primary" onClick={e => Proceed(e)}>
                                    Continue to Incident Information
                                </Button>
                            </Link>
                        </div>
                    </Card.Body>
                </Card>
            </div>
        </Header>
    )
}

const mapStateToProps = state => ({
    incidentState: state.IncidentState,
    contactInfoInitialLoad: state.ContactInfoInitialLoad,
    facilityInformationState: state.FacilityInformationState,
    rulesAgreeState: state.RulesAgreeState
})

export default connect(mapStateToProps, { setIncident, setContactInitialLoad })(Contact);