import { Button, Card } from "react-bootstrap"
import { Link } from "react-router-dom";
import Header from "../components/header";
import { connect } from "react-redux";
import packJson from '../../package.json'

const Home = ({ url, facilityInformationState }) => {
    //set the title of the page
    document.title = facilityInformationState.facilityProperName + ' Public Safety Portal';
    return (
        <Header url={url} facility={facilityInformationState} useNavigatePrompt={false} >
            <div className="d-flex flex-column flex-md-row justify-content-start" style={{ paddingLeft: "2rem", paddingRight: "1.5rem", width: "100%" }}>
                {
                    !facilityInformationState.agencyURL ?
                        ''
                        :
                        <Card style={{ display: "flex", flex: 1, marginBottom: "20px", marginTop: "20px" }} >
                            <Card.Img className='d-none d-md-inline' variant="top" src="images/sheriffsOffice.png" />
                            <Card.Body className="d-flex flex-column">
                                <Card.Title>{facilityInformationState.agencyVerbiage} Office</Card.Title>
                                <Card.Text>
                                    Visit the {facilityInformationState.facilityProperName} website.
                                </Card.Text>
                                <Link to={{ pathname: facilityInformationState.agencyURL }} target="_blank" className="mt-auto">
                                    <Button className="btn-block" >{facilityInformationState.agencyVerbiage} Website</Button>
                                </Link>
                            </Card.Body>
                        </Card>
                }
                <Card className="ml-md-4" style={{ display: "flex", flex: 1, marginBottom: "20px", marginTop: "20px" }}>
                    <Card.Img className='d-none d-md-inline' variant="top" src="images/policeReport.png" />
                    <Card.Body className="d-flex flex-column">
                        <Card.Title>Incident Report</Card.Title>
                        <Card.Text>
                            File a non-emergency incident report for review.
                        </Card.Text>
                        <Link className="mt-auto" to={url + "rules"}>
                            <Button className="btn-block">File Report</Button>
                        </Link>
                    </Card.Body>
                </Card>
                {
                    !facilityInformationState.jailRosterURL ?
                        ''
                        :
                        <Card className="ml-md-4" style={{ display: "flex", flex: 1, marginBottom: "20px", marginTop: "20px" }}>
                            <Card.Img className='d-none d-md-inline' variant="top" src="images/jailRoster.png" />
                            <Card.Body className="d-flex flex-column">
                                <Card.Title>Jail Roster</Card.Title>
                                <Card.Text>
                                    View current {facilityInformationState.institutionProperName} inmate roster.
                                </Card.Text>
                                <Link to={{ pathname: facilityInformationState.jailRosterURL }} target="_blank" className="mt-auto">
                                    <Button className="btn-block">View Roster</Button>
                                </Link>
                            </Card.Body>
                        </Card>
                }
                {
                    !facilityInformationState.institutionURL ?
                        ''
                        :
                        <Card className="ml-md-4" style={{ display: "flex", flex: 1, marginBottom: "20px", marginTop: "20px" }} >
                            <Card.Img className='d-none d-md-inline' variant="top" src="images/jailInformation.png" />
                            <Card.Body className="d-flex flex-column">
                                <Card.Title>Jail Information</Card.Title>
                                <Card.Text>
                                    Visit {facilityInformationState.institutionProperName} website.
                                </Card.Text>
                                <Link to={{ pathname: facilityInformationState.institutionURL }} target="_blank" className="mt-auto">
                                    <Button className="btn-block">Jail Website</Button>
                                </Link>
                            </Card.Body>
                        </Card>
                }
            </div>
            <span style={{ fontSize: 10, color: "white" }}>{packJson.version}</span>
        </Header>

    )
}

const mapStateToProps = state => ({
    facilityInformationState: state.FacilityInformationState,
})

export default connect(mapStateToProps)(Home);