import StyledInput from '../components/StyledInput';
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";
import { setIncident, setSummaryInitialLoad, setIncidentNumber } from "../redux/actions";
import { useState, useEffect, useRef } from 'react'
import { DefaultSummaryErrorState } from "../helpers/DefaultContactInfoErrorState";
import moment from 'moment';
import { Breadcrumb, Button, Table, Card } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";
import Header from "../components/header";
import {
    BASE_URL,
    SEND_VERIFICATION_TEXT,
    CREATE_INCIDENT,
    RECAPTCHA_CREATE_ACTION,
    RECAPTCHA_SITE_KEY,
    VALIDATE_MOBILE_CODE_AND_SUBMIT,
    GET_FACILITY_ACCESS
} from '../constants';
import { Spinner } from 'react-bootstrap'
import { toast } from 'react-toastify';
import { Markup } from 'interweave';
import { load } from 'recaptcha-v3'
import Modal from '../components/Modal';
import { ValidateMobileIncident } from '../models/ValidateMobileIncident';

const Summary = ({ url, rulesAgreeState, incidentState, setIncident, summaryInitialLoad, setSummaryInitialLoad, facilityInformationState, setIncidentNumber, incidentNumber }) => {

    let history = useHistory();
    //route to home if the user types in the direct url
    if (!rulesAgreeState) {
        history.push(url);
    }

    //if incidentNumber has value and the action is pop, the back button was used to get back to summary. 
    //take the user back home to prevent duplicate
    if (incidentNumber && history.action === "POP") {
        window.location.href = url;
    }

    const [showModal, setShowModal] = useState(false);
    const [showCodeInput, setshowCodeInput] = useState(false);
    const windowUrl = window.location.pathname;
    const baseURl = windowUrl.split('/')[1];

    const [errors, setErrors] = useState(DefaultSummaryErrorState);
    const [savingIncident, setSavingIncident] = useState(false);
    const [phoneNumber, setPhoneNumber] = useState('');
    const [smsCode, setSmsCode] = useState('');

    const bodyRef = useRef(null);

    const requiredText = '*Required';

    useEffect(() => {
        window.scrollTo(0, 0);
        //when we mount, run this function
        // do not validate data on the inital mount
        if (summaryInitialLoad) {
            setSummaryInitialLoad(false);

        } else {
            validateAllInput();
        }
    }, [])

    //
    useEffect(() => {
        if (!bodyRef.current)
            bodyRef.current = document.getElementById("body");

        if (showModal) {
            bodyRef.current.style.overflow = "hidden"
        }
        else {
            bodyRef.current.style.overflow = "auto"

        }
    }, [showModal])

    const validateMobileProceed = async () => {
        const apiURL = BASE_URL + VALIDATE_MOBILE_CODE_AND_SUBMIT;

        let validateMobileIncident = { ...ValidateMobileIncident };
        validateMobileIncident.CreatePublicIncident = { ...incidentState };
        validateMobileIncident.phoneNumber = phoneNumber;
        validateMobileIncident.code = smsCode;

        var response = await fetch(apiURL, {
            method: 'POST',
            headers: {
                'Accept': '*/*',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(validateMobileIncident)
        })

        let responseJson;

        try {
            responseJson = await response.json();
            if (responseJson?.success) {
                ShowSuccess(responseJson.data.toUpperCase());
            }
            else{
                toast.error('Validating mobile code failed. Please contact agency to report incident.');
            }
        }
        catch (e) {
            toast.error('Submission Failed: Error 103.');
        }
    }

    const sendVerficationText = async () => {
        const apiURL = BASE_URL + SEND_VERIFICATION_TEXT;

        var response = await fetch(apiURL, {
            method: 'POST',
            headers: {
                'Accept': '*/*',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ phoneNumber })
        })

        let responseJson;

        try {
            responseJson = await response.json();

            if (responseJson?.success) {
                setshowCodeInput(true);
            }
            else {
                toast.error('Verification message could not be sent. Please contact agency to report incident.');
            }


        } catch (e) {

            toast.error('Submission Failed: Error 102.');
            return;
        }
    }

    const ShowSuccess = async (incidentNumber) => {
        setIncidentNumber(incidentNumber)

        toast.success('Incident successfully filed.', {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light"
        });
        history.push(url + "success");
        return;
    }

    const Proceed = async (e) => {
        try {

            let hasErrored = false;
            setSavingIncident(true)

            const apiURL = BASE_URL + CREATE_INCIDENT;

            Object.keys(errors).map((error, index) => {
                if (!errors[error]) {
                    hasErrored = true;
                }
            })

            if (hasErrored) {
                //this should show the errors on the controls that need input
                validateAllInput();
                e.preventDefault();
                setSavingIncident(false)
                return;
            }

            //recaptcha token retrieved
            const recaptchaInstance = await load(RECAPTCHA_SITE_KEY);
            const recaptchaToken = await recaptchaInstance.execute(RECAPTCHA_CREATE_ACTION);

            let newIncidentInformation = { ...incidentState };
            newIncidentInformation.reCaptchaToken = recaptchaToken;
            if (!newIncidentInformation.facilityId) {
                const url = BASE_URL + GET_FACILITY_ACCESS + `?facilityCode=${baseURl}`;
                const res = await fetch(url, { method: "GET" });

                if (res?.ok) {
                    const json = await res.json();
                    newIncidentInformation.facilityId = json.data.id;
                }
            }
            var response = await fetch(apiURL, {
                method: 'POST',
                headers: {
                    'Accept': '*/*',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(newIncidentInformation)
            })

            let responseJson;

            try {
                responseJson = await response.json();
            } catch (e) {
                toast.error('Submission Failed: Error 101.');
                return;
            }

            if (!responseJson?.isSuccessful) {
                let errorCode = responseJson.errors.errorCode;
                let statusText = responseJson.errors.status;

                if (errorCode == '1008') {
                    //recaptcha error, show the two auth modal
                    setShowModal(true);
                }
                else {

                    toast.error('Submission Failed: Error ' + errorCode + ' ' + statusText);

                    for (var i = 0; i < responseJson?.errors?.errorList?.length; i++) {
                        toast.error('Submission Failed: ' + responseJson.errors.errorList[i].errorMessage);
                    }
                }

            }
            else {
                ShowSuccess(responseJson.data.incidentNumber);
            }
        }
        catch (e) {
            history.push(url + "error");
        }
        setSavingIncident(false);
    }

    const validateAllInput = () => {
        let allErrors = { ...errors };
        allErrors = validateInputOnChange(incidentState.signature, "signature", allErrors);
        allErrors = validateInputOnChange(incidentState.signatureDate, "signatureDate", allErrors);
        setErrors(allErrors);
    }

    const validateInputOnChange = (text, field, allErrors) => {
        let newErrors = null;

        if (!allErrors) {
            newErrors = { ...errors };
        }
        else {
            newErrors = { ...allErrors };
        }

        let newIncidentInformation = { ...incidentState };
        switch (field) {
            case "signature":
                if (!text) {
                    newErrors.signature = false;
                }
                else {
                    newErrors.signature = true;
                }
                newIncidentInformation.signature = text;
                break;
            case "signatureDate":
                if (!text) {
                    newErrors.signatureDate = false;
                    newIncidentInformation.signatureDate = text;
                }
                else {

                    let input = text;
                    input = input.replace(/\D/g, ''); // Remove any non-digit characters
                    if (input.length > 0) {
                        if (input.length < 3) {
                            input = input.replace(/^(\d{1,2})/, '$1'); // Format the month
                        } else if (input.length < 5) {
                            input = input.replace(/^(\d{1,2})(\d{1,2})/, '$1/$2'); // Format the day
                        } else {
                            input = input.replace(/^(\d{1,2})(\d{1,2})(\d{1,4})/, '$1/$2/$3'); // Format the year
                        }
                    }

                    //if today's date has value, validate format and make sure it's today
                    let inputDate = moment(input, ['MM/DD/YYYY', 'MM-DD-YYYY'], true);
                    let current = new Date();
                    let month = ("0" + (current.getMonth() + 1).toString()).slice(-2);
                    let day = ("0" + current.getDate().toString()).slice(-2);
                    let year = current.getFullYear();
                    let today = moment(month + '/' + day + '/' + year, 'MM/DD/YYYY', true)
                    let inputIsToday = inputDate.isSame(today);

                    if (inputDate.isValid() && inputIsToday) {
                        newErrors.signatureDate = true;
                    }
                    else {
                        newErrors.signatureDate = false;
                    }
                    newIncidentInformation.signatureDate = input;
                }

                break;
        }

        if (allErrors == null) {
            setErrors(newErrors);
        }
        else {
            return newErrors;
        }

        setIncident(newIncidentInformation);
    }

    return (

        <div>
            {
                savingIncident ?

                    <div style={{ width: "100%", height: window.outerHeight, display: "flex", justifyContent: "center", alignItems: "center" }}>
                        <Spinner animation={"border"} />
                    </div>
                    :
                    <Header url={url} facility={facilityInformationState} useNavigatePrompt={true} >
                        <Breadcrumb style={{}}>
                            <LinkContainer to={url + "incident"} exact>
                                <Breadcrumb.Item >Incident Information</Breadcrumb.Item>
                            </LinkContainer>
                            <Breadcrumb.Item active>Summary and Signature</Breadcrumb.Item>
                        </Breadcrumb>

                        <div className="d-flex flex-column flex-md-row justify-content-start"
                            style={{
                                paddingLeft: "2rem",
                                paddingRight: "1.5rem",
                                width: "100%",
                                backgroundColor: "rgba(255,255,255,0.5)"
                            }}>
                            <Card style={{ display: "flex", flex: 1, marginBottom: "20px" }}>
                                <Card.Header as="h5">Summary</Card.Header>
                                <Card.Body>
                                    <Table bordered size="sm">
                                        <thead>
                                            <tr>
                                                <th>Contact Information</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>Name: {incidentState.reporter.firstName + ' ' + incidentState.reporter.lastName}</td>
                                            </tr>
                                            <tr>
                                                <td>Phone: {incidentState.reporter.phoneNumber}</td>
                                            </tr>
                                            <tr>
                                                <td>Email: {incidentState.reporter.emailAddress}</td>
                                            </tr>
                                        </tbody>
                                    </Table>
                                    <Table bordered style={{}} size="sm">
                                        <thead>
                                            <tr>
                                                <th>Address</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>{incidentState.reporter.address.addressLine1}</td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    {
                                                        !incidentState.reporter.address.addressLine2 ? <>&nbsp;</> : incidentState.reporter.address.addressLine2
                                                    }
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>{incidentState.reporter.address.city + ', ' + incidentState.reporter.address.state + ' ' + incidentState.reporter.address.postalCode}</td>
                                            </tr>
                                        </tbody>
                                    </Table>
                                    <Table bordered style={{ tableLayout: "fixed" }} size="sm">
                                        <thead>
                                            <tr>
                                                <th>Incident Information</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>{incidentState.problemNature}</td>
                                            </tr>
                                            <tr>
                                                <td>Location: {incidentState.location}</td>
                                            </tr>
                                            <tr>
                                                <td>Description: {incidentState.description}</td>
                                            </tr>
                                            <tr>
                                                <td>Property: {incidentState.propertyDescription}</td>
                                            </tr>
                                        </tbody>
                                    </Table>
                                </Card.Body>
                            </Card>
                            <Card className="ml-md-4" style={{ display: "flex", flex: 1, marginBottom: "20px" }}>
                                <Card.Header as="h5">
                                    Signature
                                </Card.Header>
                                <Card.Body>
                                    <Card style={{ display: "flex", flex: 1, marginBottom: "20px" }}>
                                        <Card.Header as="h5">Review your report</Card.Header>
                                        <Card.Body style={{ backgroundColor: "#D4EDDA" }}>
                                            <Markup content={facilityInformationState.summaryVerbiage} />
                                        </Card.Body>
                                    </Card>
                                    <StyledInput
                                        title={"Signature"}
                                        type={"text"}
                                        isValid={errors.signature}
                                        validationMessage={requiredText}
                                        onChangeText={(text) => validateInputOnChange(text, 'signature')}
                                        value={incidentState.signature}
                                    >
                                        <p style={{ fontSize: 12 }}>
                                            Full Name
                                        </p>
                                    </StyledInput>
                                    <StyledInput
                                        title={"Today's Date"}
                                        type={"text"}
                                        isValid={errors.signatureDate}
                                        validationMessage={requiredText}
                                        onChangeText={(text) => validateInputOnChange(text, 'signatureDate')}
                                        value={incidentState.signatureDate}
                                    >
                                        <p style={{ fontSize: 12 }}>
                                            MM/DD/YYYY
                                        </p>
                                    </StyledInput>

                                    <div style={{ float: "right" }}>
                                        <Button variant="primary" onClick={e => Proceed(e)}>
                                            Sign and Submit
                                        </Button>
                                    </div>
                                </Card.Body>
                            </Card>

                        </div>

                        {showModal &&
                            <div style={{
                                backgroundColor: 'rgba(0,0,0,0.5)',
                                border: '1px solid #ccc',
                                boxShadow: '-2rem 2rem 2rem rgba(black, 0.2)',
                                position: "absolute",
                                inset: "0 0 0 0",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center"
                            }}>
                                <div style={{ width: 500, height: 450, backgroundColor: "white" }}>
                                    <h4 style={{
                                        borderBottom: '1px solid #ccc',
                                        padding: '1rem',
                                        margin: 0
                                    }}>
                                        Security Step
                                        <Button style={{ float: 'right' }} onClick={() => setShowModal(false)}>Close</Button>
                                    </h4>
                                    <div style={{
                                        padding: '1rem'
                                    }}>
                                        <div style={{ padding: '.5rem' }}>
                                            <p>
                                                Please help us verify that you are not a robot. Enter your mobile phone number and we will send a verification code.
                                            </p>
                                            <StyledInput
                                                type={"text"}
                                                value={phoneNumber}
                                                onChange={(e) => setPhoneNumber(e.target.value)}

                                            />
                                            <Button onClick={sendVerficationText}>
                                                Send verification Text
                                            </Button>
                                        </div>
                                        {
                                            showCodeInput &&
                                            <div style={{ padding: '.5rem' }}>
                                                <p>
                                                    Enter the code that has been sent to the number above.
                                                </p>
                                                <StyledInput
                                                    type={"text"}
                                                    value={smsCode}
                                                    onChange={(e) => setSmsCode(e.target.value)}
                                                />
                                                <Button onClick={validateMobileProceed}>
                                                    Verify
                                                </Button>
                                            </div>
                                        }

                                    </div>
                                </div>

                            </div>
                        }
                    </Header>
            }
        </div>

    )
}

const mapStateToProps = state => ({
    incidentState: state.IncidentState,
    summaryInitialLoad: state.SummaryInitialLoad,
    facilityInformationState: state.FacilityInformationState,
    rulesAgreeState: state.RulesAgreeState,
    incidentNumber: state.IncidentNumberState
})

export default connect(mapStateToProps, { setIncident, setSummaryInitialLoad, setIncidentNumber })(Summary);