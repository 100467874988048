import React from 'react';
import { Form } from 'react-bootstrap';

const CheckBox = ({ title, value, onChange, disabled, titleStyle }) => {

    return (
        <>
            <Form>
                <Form.Group className="mb-3" controlId="formBasicCheckbox">
                    <Form.Check disabled={disabled} type="checkbox" style={{...titleStyle}} label={title} onChange={() => disabled ? null : onChange(!value)} checked={value} />
                </Form.Group>
            </Form>
        </>
    );
};
export default CheckBox;