import * as Types from './constants'

export const setIncident = value => ({
    type: Types.SET_INCIDENT, value
})

export const setContactInitialLoad = value => ({
    type: Types.CONTACT_INFO_INIT_LOAD, value
})

export const setIncidentInitialLoad = value => ({
    type: Types.INCIDENT_INFO_INIT_LOAD, value
})

export const setSummaryInitialLoad = value => ({
    type: Types.SUMMARY_INIT_LOAD, value
})

export const setWindow = value => ({
    type: Types.SET_WINDOW, value
})

export const setRulesAgree = value => ({
    type: Types.RULES_AGREE, value
})

export const setProceduresAgree = value => ({
    type: Types.PROCEDURES_AGREE, value
})

export const setFacilityInformation = value => ({
    type: Types.FACILITY_INFORMATION, value
})

export const setIncidentNumber = value => ({
    type: Types.INCIDENT_NUMBER, value
})