import React, { useState, useEffect } from 'react'
import { BrowserRouter as Router, Switch, Route } from "react-router-dom"
import Procedures from './pages/procedures'
import Rules from './pages/rules'
import Denied from './pages/denied'
import { Spinner } from 'react-bootstrap'
import Summary from './pages/summary'
import Incident from './pages/incident'
import Contact from './pages/contact'
import Success from './pages/success'
import Home from './pages/home'
import Error from './pages/error'
import { setFacilityInformation, setIncident } from './redux/actions'
import { connect } from 'react-redux'
import { BASE_URL, GET_FACILITY_ACCESS } from './constants'
import Status from './pages/status'
import PrivacyPolicy from './pages/privacyPolicy'

const Routes = ({ setFacilityInformation, facilityInformationState }) => {

    const windowUrl = window.location.pathname;
    const baseURl = windowUrl.split('/')[1];
    const baseURlwSlashes = '/' + windowUrl.split('/')[1] + '/'
    const [facilityLoaded, setFacilityLoaded] = useState(false);
    const [serverError, setServerError] = useState(false);

    useEffect(() => {
        //component mounted
        if (windowUrl.includes("index.html")) {
            window.location.href = "/";
        }
        loadFacility();
    }, [/* component updated */])

    useEffect(() => {
        //component mounted
            setFacilityLoaded(facilityInformationState.id!== '')
    }, [facilityInformationState])

    
    const loadFacility = async () => {
        try {
            let urlParam = baseURl;
            const url = BASE_URL + GET_FACILITY_ACCESS + `?facilityCode=${urlParam}`;
            const res = await fetch(url, { method: "GET" }); //ensure we send a get request

            if (res?.ok) {
                const json = await res.json();

                if (json && json.data) {
                    setFacilityInformation(({
                        ...facilityInformationState,
                        id: json.data.id,
                        isWebActive: json.data.isWebActive,
                        facilityProperName: json.data.facilityProperName,
                        mailingAddress: json.data.mailingAddress,
                        shippingAddress: json.data.shippingAddress,
                        jailRosterURL: json.data.jailRosterURL,
                        agencyURL: json.data.agencyURL,
                        institutionURL: json.data.institutionURL,
                        institutionProperName: json.data.institutionProperName,
                        rulesVerbiage: json.data.rulesVerbiage,
                        procedureVerbiage: json.data.procedureVerbiage,
                        incidentWhereNote: json.data.incidentWhereNote,
                        incidentPropertyNote: json.data.incidentPropertyNote,
                        physicalAddressNote: json.data.physicalAddressNote,
                        summaryVerbiage: json.data.summaryVerbiage,
                        incidentTypes: json.data.incidentTypes,
                        headerVerbiage: json.data.headerVerbiage,
                        agencyVerbiage: json.data.agencyVerbiage
                    }));
                }
                else{
                    throw 'Unable to load facility';
                }
            }
            else{
                //error occurred communicating with server 
                //error page will show 
                setFacilityLoaded(true);
                setServerError(true);
            }
        }
        catch (e) {
            //error occurred communicating with server 
            //error page will show 
            setFacilityLoaded(true);
            setServerError(true);
        }
    }

    const generateRoutes = () => {
        if (serverError) {
            return <Error />
        }

        // if pathname is null or / deny access
        if (!windowUrl || windowUrl === "/") {
            return <Denied />
        }

        //if the facility in url is not found, show denied screen
        if (!facilityInformationState?.isWebActive) {
            return <Denied />
        }

        const linkURL = baseURlwSlashes;

        return (
            <Switch>
                <Route path={linkURL + 'error'}>
                    <Error />
                </Route>
                <Route path={linkURL + 'privacy'}>
                    <PrivacyPolicy />
                </Route>
                <Route path={linkURL + 'status'}>
                    <Status url={linkURL} />
                </Route>
                <Route path={linkURL + 'success'}>
                    <Success url={linkURL} />
                </Route>
                <Route path={linkURL + 'summary'}>
                    <Summary url={linkURL} />
                </Route>
                <Route path={linkURL + 'incident'}>
                    <Incident url={linkURL} />
                </Route>
                <Route path={linkURL + 'contact'}>
                    <Contact url={linkURL} />
                </Route>
                <Route path={linkURL + 'procedures'}>
                    <Procedures url={linkURL} />
                </Route>
                <Route path={linkURL + 'rules'}>
                    <Rules url={linkURL} />
                </Route>
                <Route path={linkURL}>
                    <Home url={linkURL} />
                </Route>
            </Switch>
        )
    }

    return (
        <div>
            {
                facilityLoaded ?
                    <Router>
                        {
                            generateRoutes()
                        }
                    </Router>
                    :
                    <div style={{ width: "100%", height: window.outerHeight, display: "flex", justifyContent: "center", alignItems: "center" }}>
                        <Spinner animation={"border"} />
                    </div>
            }
        </div>
    )
}

const mapStateToProps = state => ({
    facilityInformationState: state.FacilityInformationState
})

export default connect(mapStateToProps, { setFacilityInformation })(Routes);