import { combineReducers } from "redux";
import { DefaultIncidentState } from "../models/DefaultIncidentState";
//import { DevIncidentState as DefaultIncidentState } from '../helpers/devHelper'
import { FacilityInformation } from "../models/FacilityInformation";
import * as Types from './constants'


const IncidentState = (state = DefaultIncidentState, action) => {
    switch (action.type) {
        case Types.SET_INCIDENT:
            return action.value;
        default:
            return state;
    }
}

const FacilityInformationState = (state = FacilityInformation, action) => {
    switch (action.type) {
        case Types.FACILITY_INFORMATION:
            return action.value;
        default:
            return state;
    }
}

const ContactInfoInitialLoad = (state = true, action) => {
    switch (action.type) {
        case Types.CONTACT_INFO_INIT_LOAD:
            return action.value;
        default:
            return state;
    }
}

const IncidentInitialLoad = (state = true, action) => {
    switch (action.type) {
        case Types.INCIDENT_INFO_INIT_LOAD:
            return action.value;
        default:
            return state;
    }
}

const SummaryInitialLoad = (state = true, action) => {
    switch (action.type) {
        case Types.SUMMARY_INIT_LOAD:
            return action.value;
        default:
            return state;
    }
}

const WindowState = (state = {width:window.innerWidth,height:window.innerHeight}, action) => {
    switch (action.type) {
        case Types.SET_WINDOW:
            return action.value;
        default:
            return state;
    }
}

const RulesAgreeState = (state = false, action) => {
    switch (action.type) {
        case Types.RULES_AGREE:
            return action.value;
        default:
            return state;
    }
}

const ProceduresAgreeState = (state = false, action) => {
    switch (action.type) {
        case Types.PROCEDURES_AGREE:
            return action.value;
        default:
            return state;
    }
}

const IncidentNumberState = (state = '', action) => {
    switch (action.type) {
        case Types.INCIDENT_NUMBER:
            return action.value;
        default:
            return state;
    }
}

export default combineReducers({
    IncidentState,
    ContactInfoInitialLoad,
    IncidentInitialLoad,
    SummaryInitialLoad,
    WindowState,
    RulesAgreeState,
    ProceduresAgreeState,
    FacilityInformationState,
    IncidentNumberState
})