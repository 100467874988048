export const BASE_URL = "/api/publicincidents/"
//export const BASE_URL = "http://localhost:11113/api/publicincidents/"

export const CREATE_INCIDENT = "CreatePublicIncident"

export const GET_FACILITY_ACCESS = "GetFacilityAccess"

export const GET_REPORT_STATUS = "GetPublicReportStatus"

export const RECAPTCHA_CREATE_ACTION = "createIncident"

export const SEND_VERIFICATION_TEXT = "SendVerificationText"

export const VALIDATE_MOBILE_CODE_AND_SUBMIT = "ValidateMobileCodeAndSubmit"

export const RECAPTCHA_SITE_KEY = "6Lfj7CEeAAAAABEmErInGIPQzaHyHIKK1XI_vBws"

export const EMAIL_REGEX = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

//allows a country code
export const PHONE_REGEX = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im
//10 digits only
//export const PHONE_REGEX = /^\d{10}$/

export const NAVIGATE_AWAY_ALERT = "Leaving the reporting wizard will not save the incident information. Are you sure you want to continue?"