import Header from "../components/header";
import { connect } from "react-redux";

const PrivacyPolicy = ({ url, facilityInformationState }) => {
    return (
        <Header url={url} facility={facilityInformationState} useNavigatePrompt={false} >
            <div style={{paddingTop:20,paddingBottom:20,maxWidth:"62.5em", marginRight:"auto", marginLeft:"auto"}}>
                <div class="text-center">
                    <h3>Tiger Correctional - Website Usage Terms and Conditions</h3>
                </div>
                <div>
                    <p>
                        Welcome to our website. If you continue to browse and use this website, you are agreeing to comply with and be bound by the following terms and conditions of use
                        , which together with our privacy policy govern Tiger Correctional Services, Inc.'s relationship with you in relation to this website.
                        If you disagree with any part of these terms and conditions, please do not use our website.
                    </p>
                    <p>The terms “Tiger Correctional Services, Inc.”, “Tiger”, ”us” and “we” refer to the owner of the website. The terms “you” and “your” refers to the user or viewer of our website.</p>
                    <p>
                        The use of this website is subject to the following terms of use:
                    </p>
                    <ul>
                        <li>The content of the pages of this website is for your general information and use only. It is subject to change without notice.</li>
                        <li>Neither we nor any third parties provide any warranty or guarantee as to the accuracy, timeliness, performance
                            , completeness or suitability of the information and materials found or offered on this website for any particular purpose.
                            You acknowledge that such information and materials may contain inaccuracies or errors and we expressly exclude liability
                            for any such inaccuracies or errors to the fullest extent permitted by law.
                        </li>
                        <li>Your use of any information or materials on this website is entirely at your own risk, for which we shall not be liable.
                            It shall be your own responsibility to ensure that any products, services or information available through this website meet your specific requirements.
                        </li>
                        <li>This website contains material which is owned by or licensed to us. This material includes, but is not limited to, the design, layout, look, appearance and graphics.
                            Reproduction is prohibited other than in accordance with the copyright notice, which forms part of these terms and conditions.
                        </li>
                        <li>All trademarks reproduced in this website which are not the property of, or licensed to, the operator are acknowledged on the website.
                        </li>
                        <li>Unauthorized use of this website may give rise to a claim for damages and/or be a criminal offence.
                        </li>
                        <li>From time to time this website may also include links to other websites. These links are provided for your convenience to provide further information.
                            They do not signify that we endorse the website(s). We have no responsibility for the content of the linked website(s).
                        </li>
                        <li>Your use of this website and any dispute arising out of such use of the website is subject to the laws of the United States of America.
                        </li>
                    </ul>
                    <h4>Tiger Correctional Services, Inc.'s privacy policy</h4>
                    <p>
                        This privacy policy sets out how Tiger Correctional Services, Inc. uses and protects any information that you give Tiger Correctional Services, Inc. when you use this website.
                    </p>
                    <p>
                        Tiger Correctional Services, Inc. is committed to ensuring that your privacy is protected.
                        Should we ask you to provide certain information by which you can be identified when using this website, you can be assured that it will only be used in accordance with this privacy statement.
                    </p>
                    <p>
                        Tiger Correctional Services, Inc. may change this policy from time to time by updating this page.
                        You should check this page from time to time to ensure that you are happy with any changes. This policy is effective from 10/19/2010.
                    </p>
                    <h4>What we collect</h4>
                    <p>We may collect the following information:</p>
                    <ul>
                        <li>Name</li>
                        <li>Contact information including email address, phone number, and physical and/or mailing address</li>
                    </ul>
                    <h4>What we do with the information we gather</h4>
                    <ul>
                        <li>Internal record keeping.</li>
                        <li>We may use the information to improve our products and services.</li>
                        <li>We may periodically send promotional emails about new products
                            , special offers or other information which we think you may find interesting using the email address which you have provided.
                        </li>
                    </ul>
                    <h4>Security</h4>
                    <p>
                        We are committed to ensuring that your information is secure.
                        In order to prevent unauthorized access or disclosure, we have put in place suitable physical, electronic and managerial procedures to safeguard and secure the information we collect online.
                    </p>
                    <h4>Links to other websites</h4>
                    <p>
                        Our website may contain links to other websites of interest.
                        However, once you have used these links to leave our site, you should note that we do not have any control over that other website.
                        Therefore, we cannot be responsible for the protection and privacy of any information which you provide whilst visiting such sites and such sites are not governed by this privacy statement.
                        You should exercise caution and look at the privacy statement applicable to the website in question.
                    </p>
                    <h4>Website disclaimer</h4>
                    <p>
                        The information contained in this website is for general information purposes only.
                        The information is provided by Tiger Correctional Services, Inc. and while we endeavor to keep the information up to date and correct
                        , we make no representations or warranties of any kind, express or implied, about the completeness, accuracy, reliability, suitability
                        or availability with respect to the website or the information, products, services, or related graphics contained on the website for any purpose.
                        Any reliance you place on such information is therefore strictly at your own risk.
                    </p>
                    <p>
                        In no event will we be liable for any loss or damage including without limitation, indirect or consequential loss or damage
                        , or any loss or damage whatsoever arising from loss of data or profits arising out of, or in connection with, the use of this website.
                    </p>
                    <p>
                        Through this website you are able to link to other websites which are not under the control of Tiger Correctional Services, Inc.
                        We have no control over the nature, content and availability of those sites.
                        The inclusion of any links does not necessarily imply a recommendation or endorse the view expressed within them.
                    </p>
                    <p>
                        Every effort is made to keep the website up and running smoothly.
                        However, Tiger Correctional Services, Inc. takes no responsibility for, and will not be liable for, the website being temporarily unavailable due to technical issues beyond our control.
                    </p>
                  
                </div>
            </div>

        </Header>
    )
}

const mapStateToProps = state => ({
    facilityInformationState: state.FacilityInformationState,
})

export default connect(mapStateToProps, {})(PrivacyPolicy);