import Header from "../components/header";
import { connect } from "react-redux";
import { Card } from "react-bootstrap"
import { useState, useEffect } from 'react'
import { BASE_URL, GET_REPORT_STATUS } from '../constants';
import { Spinner } from 'react-bootstrap'
import { useHistory } from "react-router-dom";

const Status = ({ url, facilityInformationState }) => {

    let history = useHistory();

    const windowUrl = window.location.pathname;
    const incidentNumber = windowUrl.split('/')[3];

    const [isSearching, setIsSearching] = useState(true);
    const [incidentStatus, setIncidentStatus] = useState({
        status: null,
        submittedDate: null,
        facilityId: null,
        incidentId: null
    });

    useEffect(() => {
        //component mounted
        FindReportStatus();
    }, [/* component updated */])

    const FindReportStatus = async () => {

        if (!incidentNumber) {
            history.push(url)
        }

        setIsSearching(true);

        const endpointUrl = BASE_URL + GET_REPORT_STATUS + `?incidentNumber=${incidentNumber}`;

        const res = await fetch(endpointUrl, { method: "GET" }); //ensure we send a get request


        if (!res?.ok) {
            history.push(url)
        }

        const json = await res.json();

        if (json && json.data) {
            setIncidentStatus(({
                ...incidentStatus,
                status: json.data.status?.toLowerCase(),
                submittedDate: json.data.submittedDate,
                facilityId: json.data.facilityId,
                incidentID: json.data.incidentID
            }))
        }
        else {
            history.push(url)
        }

        setIsSearching(false);

    }

    return (
        <Header url={url} facility={facilityInformationState} useNavigatePrompt={false} >
            <div className="d-flex flex-column flex-md-row justify-content-start" style={{ paddingLeft: "2rem", paddingRight: "1.5rem", width: "100%", marginTop: "20px" }}>

                <Card className="ml-md-4" style={{ display: "flex", flex: 1, marginBottom: "20px" }}>
                    <Card.Header as="h5">
                        Incident Report Status
                    </Card.Header>
                    <Card.Body>
                        {
                            isSearching ?
                                <div style={{ width: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                                    <Spinner animation={"border"} />
                                </div>
                                :
                                <div>
                                    <h4>
                                        Incident {incidentNumber.toUpperCase()} was submitted on {incidentStatus.submittedDate} and is {incidentStatus.status}
                                    </h4>
                                </div>
                        }
                    </Card.Body>
                </Card>
            </div>
        </Header>
    )
}

const mapStateToProps = state => ({
    facilityInformationState: state.FacilityInformationState,
})

export default connect(mapStateToProps)(Status);