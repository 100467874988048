
export const DefaultContactInfoErrorState = {
    reporterFirstName: null,
    reporterLastName: null,
    reporterEmail: null,
    reporterDOB: null,
    reporterPhone: null,
    reporterAddressStreet1: null,
    reporterCity: null,
    reporterState: null,
    reporterZip: null
}


export const DefaultIncidentInfoErrorState = {
    problemNature: null,
    location: null,
    beginTime: null,
    endTime: null,
    description: null
}

export const DefaultSummaryErrorState = {
    signature: null,
    signatureDate: null
}
